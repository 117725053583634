import React from "react"
import { graphql } from "gatsby"
import { Layout } from "@solublestudio/gatsby-theme-soluble-source"

import Section from "../sections"

export default function PostPage({ data, pageContext }) {
  return (
    <Layout
      navbarFixed={true}
      navbarTransparent={true}
      customSeo={{
        title: data.seoTitle,
        description: data.seoDescription,
      }}
      headerExtraParams={{
        url: pageContext?.pageUrl,
      }}
    >
      {data.parentPage?.sections.map((section, i) => (
        <Section
          key={i}
          order={i + 1}
          isLast={i + 1 === data.parentPage.sections.length}
          {...(section.__typename === "DatoCmsBlogSection"
            ? {
                ...section,
                title: data.seoTitle,
                subtitle: data.parentPage?.sections[0]?.subtitle,
                posts: data.posts.edges.map(({ node }) => node),
                pageNr: pageContext?.pageNr,
                numPages: pageContext?.numPages,
                titleTag: "p",
              }
            : section)}
        />
      ))}
    </Layout>
  )
}

export const query = graphql`
  query PostPage(
    $modelIds: [String]
    $language: String
    $pageNrString: String
  ) {
    seoTitle: getTranslation(
      key: "blog.pagination.seo.title"
      language: $language
      param: $pageNrString
    )
    seoDescription: getTranslation(
      key: "blog.pagination.seo.description"
      language: $language
      param: $pageNrString
    )
    parentPage: getParentPage(locale: $language, type: "DatoCmsBlogSection") {
      ...DatoCmsBlogSectionParentPage
    }
    posts: allDatoCmsBlog(filter: { id: { in: $modelIds } }) {
      edges {
        node {
          ...PostItem
        }
      }
    }
  }
`
